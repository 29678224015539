import React, { Component } from "react";
import { Link } from "react-router-dom";

class Header extends Component {
  render() {
    var user = JSON.parse(sessionStorage.getItem("usuario"));

    //console.log(window.location);

    return (
      <header id="main-header">
        <div className="container">
          <div className={this.props.class1}>
            <div className={this.props.class2}>
              <h4 className="mt-2">
                <i className={this.props.class3}></i>
                &nbsp;{this.props.title}
              </h4>
            </div>
            <div className="col-md-6 py-1">
              <div className="float-right">
                <h5>
                  {localStorage.getItem("sucursal") && (
                    <span className="badge badge-pill badge-primary mt-2">
                      {JSON.parse(localStorage.getItem("sucursal"))["Sucursal"]}
                    </span>
                  )}{" "}
                  {window.location.pathname !== "/location" && (
                    <Link to={"/location"} className="badge badge-light">
                      <i className="fas fa-exchange-alt mr-1"></i>Cambiar
                    </Link>
                  )}
                </h5>
              </div>
            </div>
          </div>
          <div className="row bg-light py-1">
            <div className="col-md-12">
              <div className="float-right">
                <i className="fas fa-user"></i>&nbsp;
                {user
                  ? user[0].Cliente.length > 30
                    ? user[0].Cliente.substring(0, 30) + "..."
                    : user[0].Cliente
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
