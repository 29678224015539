import React, { Component } from "react";

import global from "./Global";
import { numberFormat, addDefaultSrc } from "../repository";

class OrderItem extends Component {
  render() {
    const { item } = this.props;
    return (
      <li className="list-group-item">
        <div className="row">
          <div className="col-md-3 text-center">
            <img
              style={{
                maxWidth: 80 + "%",
                height: 100 + "px",
                objectFit: "contain"
              }}
              src={
                global.fotos_URL +
                item.Producto.toLowerCase().replace("-", "_") +
                ".jpg"
              }
              alt={item.Producto}
              onError={addDefaultSrc}
            ></img>
          </div>
          <div className="col-md-9 text-center">
            <h5>
              <span className="badge badge-secondary float-left mt-1">
                {item.Producto}
              </span>
              
                <span className="mt-1">&nbsp;</span>
              
              <span className="badge badge-pill badge-primary float-right mt-1">
                ${numberFormat(item.Precio)}
              </span>
            </h5>
            <div className="text-center">{item.Descripcion}</div>
            <div className="text-center">
              <span className="mr-2">
                <strong>Cantidad:</strong>
              </span>
              {item.Cantidad}
            </div>
          </div>
        </div>
      </li>
    );
  }
}

export default OrderItem;
