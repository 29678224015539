import React, { Component, Fragment } from "react";
import parse from "html-react-parser";

import global from "./Global";

class LocationItem extends Component {
  validateDuration(duration) {
    //console.log(duration);

    if (duration < 1800) return "badge badge-pill badge-success";
    else if (duration > 1800 && duration < 3600)
      return "badge badge-pill badge-warning";
    else return "badge badge-pill badge-danger";
  }

  setSucursal(sucursal) {
    //console.log(sucursal);

    localStorage.setItem("sucursal", JSON.stringify(sucursal));

    window.location = "/Home";
  }

  render() {
    return (
      <div className="col-md-4 card-group">
        <div className="card mb-4 border-secondary">
          <div className="card-header text-center">{this.props.item.Grupo}</div>
          <div className="card-body">
            <h6 className="card-title">
              <strong>{this.props.item.Sucursal}</strong>
              <div className="float-right">
                <span className="badge badge-pill badge-primary mr-2">
                  {this.props.item.Distancia}
                </span>
                <span
                  className={this.validateDuration(this.props.item.DuracionNum)}
                >
                  {this.props.item.Duracion}
                </span>
              </div>
            </h6>
            <p className="card-text">
              <Fragment>{parse(this.props.item.Direccion)}</Fragment>
            </p>
          </div>
          <div className="card-footer text-center">
            <button
              className="btn btn-warning btn-sm mr-2 mb-2"
              type="button"
              onClick={() => this.setSucursal(this.props.item)}
            >
              <i className="fas fa-check"></i>&nbsp;Seleccionar
            </button>
            <a
              target="_new"
              className="btn btn-secondary btn-sm mb-2"
              role="button"
              href={
                global.google_maps_URL +
                this.props.item.Coordenadas.split(",")[0] +
                "+" +
                this.props.item.Coordenadas.split(",")[1]
              }
            >
              <i className="fas fa-car"></i>&nbsp;Indicaciones
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default LocationItem;
