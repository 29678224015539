import React, { Component } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import Swal from "sweetalert";

import Header from "./Header";
import HomeActions from "./HomeActions";
import NavBar from "./NavBar";
import SideBar from "./SideBar";
import Footer from "./Footer";
import { getPedidos, numberFormat } from "../repository";

class Home extends Component {
  constructor() {
    super();
    this.state = {
      pedidos: {},
      status: null
    };
  }

  obtienePedidos = () => {
    var user = JSON.parse(sessionStorage.getItem("usuario"));
    var idClienteGlobal = user[0].idClienteGlobal;

    getPedidos(idClienteGlobal, 10)
      .then(response => {
        if (response.status === "success") {
          this.setState({
            status: "success",
            pedidos: response.result
          });
        } else {
          Swal(
            "Error obtener la información [Pedidos]",
            response.message,
            "error"
          );
        }
      })
      .catch(err =>
        Swal("Error obtener la información [Pedidos]", err, "error")
      );
  };

  componentDidMount() {
    this.obtienePedidos();
  }

  render() {
    //console.log(JSON.parse(localStorage.getItem("sucursal")))

    return (
      <div>
        {/* NAVBAR */}
        <NavBar />

        {/* HEADER */}
        <Header
          title="Pedidos Sucursales"
          class1="row py-1 bg-warning text-black"
          class2="col-md-6"
          class3="fa fa-store-alt"
        />

        {/* ACTIONS */}
        <HomeActions />

        {/* PEDIDOS */}
        <section id="orders">
          <div className="container">
            <div className="row">
              <div className="col-md-9 mb-3">
                <div className="card">
                  <div className="card-header">
                    <h5>Últimos 10 Pedidos</h5>
                  </div>

                  <table className="table-sm table-striped w-100">
                    <thead className="table-dark">
                      <tr>
                        <th className="text-center">#</th>
                        <th className="text-center">Total</th>
                        <th className="text-center">Fecha</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>

                    {/*Listado de documentos del API RESTful de NodeJS*/}
                    {this.state.status === "success" && (
                      <tbody>
                        {this.state.pedidos.map(item => {
                          return (
                            <tr key={item.idPedido}>
                              <td className="text-center">{item.idPedido}</td>
                              <td className="text-right">
                                <strong>${numberFormat(item.Total)}</strong>
                              </td>
                              <td className="text-center">
                                <Moment format="DD/MM/YY">
                                  {item.Fecha}
                                </Moment>
                              </td>
                              <td className="text-center">
                                <Link
                                  to={"/orderDetails/" + item.idPedido + "/1"}
                                  className="btn btn-secondary"
                                >
                                  <i className="fas fa-angle-double-right"></i>{" "}
                                  Detalle
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>

              {/* SIDEBAR*/}
              <SideBar />
            </div>
          </div>
        </section>

        {/* FOOTER */}
        <Footer />
      </div>
    );
  }
}

export default Home;
