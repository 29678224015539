import React, { Component, Fragment } from "react";
import SimpleReactValidator from "simple-react-validator";
import Swal from "sweetalert";

import { getProductosByDescripcion } from "../repository";
import ProductItem from "./ProductItem";

class SearchByDesc extends Component {
  descRef = React.createRef();

  constructor() {
    super();
    this.changeState = this.changeState.bind(this);

    this.state = {      
      desc: "",
      loading: 0,
      productos: [],
      status: null      
    };
  }

  componentWillMount() {
    this.validator = new SimpleReactValidator({
      element: (message) => (
        <span className="text-danger">
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {` ${message}`}
        </span>
      ),
      messages: {
        default: "Campo Requerido",
      },
    });
  }

  changeState = () => {

    console.log(this.descRef.current.value)

    this.setState({
      desc: this.descRef.current.value,
      productos: [],
      status: null
    });
  };

  //Search Products
  search = (e) => {
    e.preventDefault();

    this.setState({
      loading: 1,
      productos: [],
      status: null,
    });

    if (this.validator.allValid()) {
      //console.log("Validator Ok");

      getProductosByDescripcion(
        this.state.desc
      )
        .then((response) => {
          //console.log(response.result);

          if (response.status === "success") {
            this.setState({
              status: "success",
              productos: response.result,
              loading: 0,
            });
          } else {
            this.setState({ loading: 0 });
            Swal(
              "Error obtener la información [Productos]",
              response.message,
              "error"
            );
          }
        })
        .catch((err) => {
          this.setState({ loading: 0 });
          Swal("Error obtener la información [Productos]", err, "error");
        });
    } else {
      //console.log("Validator-Failed");
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({ loading: 0 });
    }
  };

  render() {
    if (this.state.status === "success") {
      var productos = this.state.productos;

      //console.log("productos:" + productos.length);
    }

    return (
      <div>
        {/* CONTENT */}
        <section id="searchContent" className="mt-3">
          <form onSubmit={this.search}>
            <div className="container">
              <div className="row">
                <div className="col-md-2 mt-2">
                  <h6>Descripción:</h6>
                </div>
                <div className="col-md-7 mb-3">
                  <input
                    name="txtDesc"
                    type="text"
                    className="form-control"
                    defaultValue=""
                    ref={this.descRef}
                    autoComplete="off"
                    placeholder="Ejemplo: rotula chevy"
                    onChange={this.changeState}
                    maxLength="50"
                  />
                  {this.validator.message(
                    "txtDesc",
                    this.state.desc,
                    "required|alpha_num_dash_space"
                  )}
                </div>
                <div className="col-md-3">
                  <button className="btn btn-warning btn-block" type="submit">
                    <i className="fas fa-search mr-1"></i>Buscar
                  </button>
                </div>

              </div>
              {this.state.loading === 1 && (
                <div className="text-center mb-3 mt-3">
                  <h6>Cargando...</h6>
                  <div
                    className="spinner-border text-warning"
                    role="status"
                    aria-hidden="true"
                  ></div>
                </div>
              )}
              {this.state.status === "success" && (
                <Fragment>
                  <div className="row mt-3">
                    <div className="col-md-3"></div>
                    <div className="col-md-6 text-center">
                      <h6>
                        <span className="badge badge-warning ml-1 mr-1">
                          {productos.length}
                        </span>
                        <small>
                          <strong>Producto(s) encontrado(s)</strong>
                        </small>
                      </h6>
                      <div className="col-md-3"></div>
                    </div>
                  </div>
                  <div className="row">
                    {productos.map((item, index) => (
                      <ProductItem
                        item={item}
                        key={index}
                        add={this.props.addToCart}
                      />
                    ))}
                  </div>
                </Fragment>
              )}
            </div>
          </form>
        </section>
      </div>
    );
  }
}

export default SearchByDesc;
