import React, { Component, Fragment } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert";

import Header from "./Header";
import NavBar from "./NavBar";
import Footer from "./Footer";
import CartItem from "./CartItem";
import {
  numberFormat,
  agregaPedidos,
  agregaDetallePedidos,
} from "../repository";

class Cart extends Component {
  consignadoRef = React.createRef();

  constructor() {
    super();

    this.getCart = this.getCart.bind(this);
    this.saveOrder = this.saveOrder.bind(this);
    this.changeState = this.changeState.bind(this);

    this.state = {
      status: null,
      productos: {},
      subtotal: 0,
      items: 0,
      idSucursal: 0,
      consignado: null,
      orderStatus: "",
    };
  }

  getCart = () => {
    this.refs.navBarRef.getCartItems();

    if (sessionStorage.getItem("cart") !== null) {
      var cart = JSON.parse(sessionStorage.getItem("cart"));

      //console.log(cart)

      //Calculo el subtotal y items
      var subtotal = 0;
      var items = 0;
      Object.entries(cart).forEach(([key, item]) => {
        if (item.Inventario >= item.Cantidad) {
          subtotal += item.Precio * item.Cantidad;
          items += item.Cantidad;
        } else {
          subtotal += 0;
          items += 0;
        }
      });

      //console.log(subtotal, items);

      this.setState({
        status: Object.keys(cart).length > 0 ? "success" : "failed",
        productos: cart,
        subtotal: subtotal,
        items: items,
      });
    }
  };

  saveOrder = async (e) => {
    e.preventDefault();

    var user = JSON.parse(sessionStorage.getItem("usuario"));
    var idClienteGlobal = user[0].idClienteGlobal;
    var idSucursal = JSON.parse(localStorage.getItem("sucursal"))["idSucursal"];

    if (this.validator.allValid()) {
      var cart = this.state.productos;

      var idPedido = await agregaPedidos(
        idClienteGlobal,
        idSucursal,
        this.state.subtotal,
        this.state.consignado
      );
      //console.log(idPedido);

      if (idPedido > 0) {
        Object.entries(cart).map(async ([key, item]) => {
          //Solo los productos que estan disponibles
          if (item.Inventario >= item.Cantidad) {
            await agregaDetallePedidos(
              idPedido,
              item.Codigo,
              item.Descripcion
                ? item.Descripcion
                : item.Familia +
                    " / " +
                    item.Marca +
                    " / " +
                    item.Vehiculo +
                    " / " +
                    item.Modelo +
                    " / " +
                    item.Posicion +
                    " / " +
                    item.Condicion,
              item.Cantidad,
              item.Precio,
              item.idMarcaProducto
            );

            //console.log(detallePedido);
          }
        });

        Swal(
          "Pedido creado",
          "El Pedido se ha generado correctamente",
          "success"
        );

        sessionStorage.removeItem("cart");

        this.setState({
          orderStatus: "success",
        });
      }
    } else {
      console.log("ValidatorFailed");
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  componentDidMount() {
    this.validator = new SimpleReactValidator({
      element: (message) => (
        <span className="text-danger">
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {` ${message}`}
        </span>
      ),
      messages: {
        default: "Comentarios inválidos",
      },
    });

    this.getCart();
  }

  removeFromCart = (item) => {
    let cart = this.state.productos;
    let idProducto = item.idProducto;

    delete cart[idProducto];
    sessionStorage.setItem("cart", JSON.stringify(cart));
    this.getCart();
  };

  updateCart = (item, cant) => {
    let cart = this.state.productos;
    let idProducto = item.idProducto;

    cart[idProducto].Cantidad = cant;
    sessionStorage.setItem("cart", JSON.stringify(cart));
    this.getCart();
  };

  changeState = () => {
    this.setState({
      consignado: this.consignadoRef.current.value,
    });
  };

  render() {
    if (this.state.status === "success") {
      var productos = this.state.productos;
    }

    if (this.state.orderStatus === "success") {
      return <Redirect to="/home" />;
    }

    return (
      <div>
        {/* NAVBAR */}
        <NavBar ref="navBarRef" />

        {/* HEADER */}
        <Header
          title="Carrito de Compras"
          class1="row py-1 bg-success text-white"
          class2="col-md-6"
          class3="fa fa-shopping-cart"
        />

        {/* CONTENT */}
        <section id="items">
          <div className="container">
            {this.state.status !== "success" && (
              <div className="row mt-3">
                <div className="col-md-3"></div>
                <div className="col-md-6 text-center">
                  <small>
                    <strong>El Carrito de Compras está vacío.</strong>
                  </small>
                  <div className="col-md-3"></div>
                </div>
              </div>
            )}

            {this.state.status === "success" && (
              <Fragment>
                <div className="row mt-3">
                  <div className="col-md-12">
                    <ul className="list-group">
                      {Object.entries(productos).map(([key, value]) => (
                        <CartItem
                          ref="cartItemRef"
                          item={value}
                          key={key}
                          remove={this.removeFromCart}
                          update={this.updateCart}
                        />
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-12 text-right">
                    <h5>
                      <small>
                        <strong>Subtotal</strong>
                      </small>
                      <span className="badge badge-success ml-1 mr-1">
                        {this.state.items}
                      </span>
                      <small>
                        <strong>Productos:</strong>
                      </small>
                      <span className="badge badge-pill badge-warning ml-2 mr-3">
                        ${numberFormat(this.state.subtotal)} + IVA
                      </span>
                    </h5>
                  </div>
                </div>
                <form onSubmit={this.saveOrder}>
                  <div className="form-row">
                    <div className="col-md-10 mt-3">
                      <input
                        name="txtConsignado"
                        type="text"
                        className="form-control"
                        defaultValue=""
                        ref={this.consignadoRef}
                        autoComplete="off"
                        placeholder="Capture sus comentarios (Ej: Consignado)"
                        onChange={(e) => {
                          this.changeState();
                        }}
                        maxLength="300"
                      />
                      {this.validator.message(
                        "txtConsignado",
                        this.state.consignado,
                        "required|string"
                      )}
                    </div>
                    <div className="col-md-2 mt-3">
                      <button
                        className="btn btn-warning btn-block"
                        type="submit"
                      >
                        <i className="fas fa-paper-plane mr-1"></i>Enviar
                      </button>
                    </div>
                  </div>
                </form>
              </Fragment>
            )}
          </div>
        </section>

        {/* FOOTER */}
        <Footer />
      </div>
    );
  }
}

export default Cart;
