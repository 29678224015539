import React, { Component, Fragment } from "react";
import SimpleReactValidator from "simple-react-validator";
import Swal from "sweetalert";

import {
  getMarcas,
  getVehiculos,
  getModelos,
  getFamilias,
  getProductos,
} from "../repository";
import ProductItem from "./ProductItem";

class SearchByMaker extends Component {
  constructor() {
    super();

    this.handleComboChange = this.handleComboChange.bind(this);
    this.obtieneMarcas = this.obtieneMarcas.bind(this);
    this.obtieneVehiculos = this.obtieneVehiculos.bind(this);
    this.obtieneModelos = this.obtieneModelos.bind(this);
    this.obtieneFamilias = this.obtieneFamilias.bind(this);
    this.search = this.search.bind(this);

    this.state = {
      status: null,
      idMarca: 0,
      idVehiculo: 0,
      idModelo: 0,
      idFamilia: -1,
      marcas: [],
      vehiculos: [],
      modelos: [],
      familias: [],
      productos: [],
      cartItems: 0,
      loading: 0,
    };
  }

  componentWillMount() {
    this.validator = new SimpleReactValidator({
      element: (message) => (
        <span className="text-danger">
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {` ${message}`}
        </span>
      ),
      messages: {
        default: "Campo Requerido",
      },
    });

    this.obtieneMarcas();
  }

  handleComboChange(event) {
    //console.log("event.target.name :" + event.target.name);
    //console.log("event.target.value :" + event.target.value);

    this.setState({ [event.target.name]: event.target.value });

    if (event.target.name === "idMarca") {
      this.setState({
        idVehiculo: 0,
        vehiculos: [],
        idModelo: 0,
        modelos: [],
        idFamilia: -1,
        familias: [],
        productos: [],
        status: null,
      });

      this.obtieneVehiculos(event.target.value);
      this.obtieneModelos(0);
      this.obtieneFamilias(0, 0);
      //console.log(this.state.familias);
    }

    if (event.target.name === "idVehiculo") {
      this.setState({
        idModelo: 0,
        modelos: [],
        idFamilia: -1,
        familias: [],
        productos: [],
        status: null,
      });

      this.obtieneModelos(event.target.value);
      this.obtieneFamilias(0, 0);
    }

    if (event.target.name === "idModelo") {
      this.setState({
        idFamilia: -1,
        familias: [],
        productos: [],
        status: null,
      });

      this.obtieneFamilias(this.state.idVehiculo, event.target.value);
    }

    if (event.target.name === "idFamilia") {
      this.setState({
        productos: [],
        status: null,
      });
    }
  }

  obtieneMarcas = () => {
    getMarcas()
      .then((response) => {
        if (response.status === "success") {
          var items = response.result.map((item) => {
            return { value: item.idMarca, text: item.Marca };
          });

          this.setState({
            marcas: [{ value: 0, text: "[Seleccione]" }].concat(items),
          });
        } else {
          Swal(
            "Error obtener la información [Marcas]",
            response.message,
            "error"
          );
        }
      })
      .catch((err) =>
        Swal("Error obtener la información [Marcas]", err, "error")
      );
  };

  obtieneVehiculos = (idMarca) => {
    getVehiculos(idMarca)
      .then((response) => {
        if (response.status === "success") {
          var items = response.result.map((item) => {
            return { value: item.idTipoVehiculo, text: item.TipoVehiculo };
          });

          this.setState({
            vehiculos: [
              { value: 0, text: items.length > 0 ? "[Seleccione]" : "[---]" },
            ].concat(items),
          });
        } else {
          Swal(
            "Error obtener la información [Vehículos]",
            response.message,
            "error"
          );
        }
      })
      .catch((err) =>
        Swal("Error obtener la información [Vehículos]", err, "error")
      );
  };

  obtieneModelos = (idVehiculo) => {
    getModelos(idVehiculo)
      .then((response) => {
        if (response.status === "success") {
          var items = response.result.map((item) => {
            return { value: item.Modelo, text: item.Modelo };
          });

          this.setState({
            modelos: [
              { value: 0, text: items.length > 0 ? "[Seleccione]" : "[---]" },
            ].concat(items),
          });
        } else {
          Swal(
            "Error obtener la información [Modelos]",
            response.message,
            "error"
          );
        }
      })
      .catch((err) =>
        Swal("Error obtener la información [Modelos]", err, "error")
      );
  };

  obtieneFamilias = (idVehiculo, idModelo) => {
    //console.log("idVehiculo: " + idVehiculo);
    //console.log("idModelo: " + idModelo);

    getFamilias(idVehiculo, idModelo)
      .then((response) => {
        if (response.status === "success") {
          var items = {};

          if (idVehiculo > 0 && idModelo > 0)
            items = response.result.map((item) => {
              return { value: item.idFamilia, text: item.Familia };
            });

          this.setState({
            familias: [
              { value: -1, text: items.length > 0 ? "[Seleccione]" : "[---]" },
            ].concat(items),
          });
        } else {
          Swal(
            "Error obtener la información [Familias]",
            response.message,
            "error"
          );
        }
      })
      .catch((err) =>
        Swal("Error obtener la información [Familias]", err, "error")
      );
  };

  //Search Products
  search = (e) => {
    e.preventDefault();

    this.setState({ 
      loading: 1,
      productos: [],
      status: null
    });

    if (this.validator.allValid()) {
      //console.log("Validator Ok");

      getProductos(
        this.state.idVehiculo,
        this.state.idFamilia,
        this.state.idModelo
      )
        .then((response) => {
          //console.log(response.result);

          if (response.status === "success") {
            this.setState({
              status: "success",
              productos: response.result,
              loading: 0,
            });
          } else {
            this.setState({ loading: 0 });
            Swal(
              "Error obtener la información [Productos]",
              response.message,
              "error"
            );
          }
        })
        .catch((err) => {
          this.setState({ loading: 0 });
          Swal("Error obtener la información [Productos]", err, "error");
        });
    } else {
      //console.log("Validator-Failed");
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({ loading: 0 });
    }
  };

  render() {
    if (this.state.status === "success") {
      var productos = this.state.productos;

      //console.log("productos:" + productos.length);
    }

    return (
      <div>
        {/* CONTENT */}
        <section id="searchContent">
          <form onSubmit={this.search}>
            <div className="container">
              <div className="row mt-3">
                <div className="col-md-3 mb-3">
                  <h6>Marca:</h6>
                  <select
                    name="idMarca"
                    key="idMarca"
                    className="form-control"
                    onChange={this.handleComboChange}
                  >
                    {this.state.marcas.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.text}
                      </option>
                    ))}
                  </select>
                  {this.validator.message(
                    "idMarca",
                    this.state.idMarca,
                    "required|numeric|min:1,num"
                  )}
                </div>
                <div className="col-md-3 mb-3">
                  <h6>Vehículo:</h6>
                  <select
                    name="idVehiculo"
                    key="idVehiculo"
                    className="form-control"
                    onChange={this.handleComboChange}
                  >
                    {this.state.vehiculos.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.text}
                      </option>
                    ))}
                  </select>
                  {this.validator.message(
                    "idVehiculo",
                    this.state.idVehiculo,
                    "required|numeric|min:1,num"
                  )}
                </div>
                <div className="col-md-3 mb-3">
                  <h6>Modelo:</h6>
                  <select
                    name="idModelo"
                    key="idModelo"
                    className="form-control"
                    onChange={this.handleComboChange}
                  >
                    {this.state.modelos.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.text}
                      </option>
                    ))}
                  </select>
                  {this.validator.message(
                    "idModelo",
                    this.state.idModelo,
                    "required|numeric|min:1,num"
                  )}
                </div>
                <div className="col-md-3">
                  <h6>Familia:</h6>
                  <select
                    name="idFamilia"
                    key="idFamilia"
                    className="form-control"
                    onChange={this.handleComboChange}
                  >
                    {this.state.familias.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.text}
                      </option>
                    ))}
                  </select>
                  {this.validator.message(
                    "idFamilia",
                    this.state.idFamilia,
                    "required|numeric|min:0,num"
                  )}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-9"></div>

                {/*
                <div className="col-md-3 mb-2">
                  <Link to={"/cart"} className="btn btn-success btn-block">
                    <i className="fas fa-shopping-cart mr-1"></i>Carrito
                  </Link>
                </div>
                */}

                <div className="col-md-3">
                  <button className="btn btn-warning btn-block" type="submit">
                    <i className="fas fa-search mr-1"></i>Buscar
                  </button>
                </div>
              </div>
              {this.state.loading === 1 && (
                <div className="text-center mb-3 mt-3">
                  <h6>Cargando...</h6>
                  <div
                    className="spinner-border text-warning"
                    role="status"
                    aria-hidden="true"
                  ></div>
                </div>
              )}
              {this.state.status === "success" && (
                <Fragment>
                  <div className="row mt-3">
                    <div className="col-md-3"></div>
                    <div className="col-md-6 text-center">
                      <h6>
                        <span className="badge badge-warning ml-1 mr-1">
                          {productos.length}
                        </span>
                        <small>
                          <strong>Producto(s) encontrado(s)</strong>
                        </small>
                      </h6>
                      <div className="col-md-3"></div>
                    </div>
                  </div>
                  <div className="row">
                    {productos.map((item, index) => (
                      <ProductItem
                        item={item}
                        key={index}
                        add={this.props.addToCart}
                      />
                    ))}
                  </div>
                </Fragment>
              )}
            </div>
          </form>
        </section>
      </div>
    );
  }
}

export default SearchByMaker;
