import React, { Component } from "react";
import Swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";

import Header from "./Header";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { getInventarioZona } from "../repository";

class Stock extends Component {
  codigoRef = React.createRef();

  constructor() {
    super();
    this.state = {
      codigo: "",
      loading: 0,
      sucursales: {},
      status: ""
    };
  }

  changeState = () => {
    this.setState({
      codigo: this.codigoRef.current.value,
      sucursales: {},
      status: ""
    });
  };

  search = e => {
    e.preventDefault();

    this.setState({ 
      loading: 1,
      sucursales: {},
      status: ""
    });

    if (this.validator.allValid()) {
      //console.log("Validator Ok");

      let idSucursal = JSON.parse(localStorage.getItem("sucursal"))[
        "idSucursal"
      ];
      let codigo = this.state.codigo;

      getInventarioZona(idSucursal, codigo, 83)
        .then(response => {
          if (response.status === "success") {
            this.setState({
              status: "success",
              sucursales: response.result
            });
            this.setState({ loading: 0 });
          } else {
            Swal(
              "Error obtener la información [Inventarios]",
              response.message,
              "error"
            );
            this.setState({ loading: 0 });
          }
        })
        .catch(err => {
          Swal("Error obtener la información [Inventarios]", err, "error");
          this.setState({ loading: 0 });
        });
    } else {
      //console.log("Validator-Failed");
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({ loading: 0 });
    }
  };

  componentWillMount() {
    this.validator = new SimpleReactValidator({
      element: message => (
        <span className="text-danger">
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {` ${message}`}
        </span>
      ),
      messages: {
        default: "Código inválido"
      }
    });
  }

  render() {
    return (
      <div>
        {/* NAVBAR */}
        <NavBar />

        {/* HEADER */}
        <Header
          title="Inventarios"
          class1="row py-1 bg-primary text-white"
          class2="col-md-6"
          class3="fa fa-cubes"
        />

        <section id="stock">
          <div className="container">
            <form onSubmit={this.search}>
              <div className="row mt-3">
                <div className="col-md-1 mt-2">
                  <h6>Código:</h6>
                </div>
                <div className="col-md-3 mb-3">
                  <input
                    name="txtCodigo"
                    type="text"
                    className="form-control"
                    defaultValue=""
                    ref={this.codigoRef}
                    autoComplete="off"
                    placeholder="Capture el Código"
                    onChange={this.changeState}
                    maxLength="20"
                  />
                  {this.validator.message(
                    "txtCodigo",
                    this.state.codigo,
                    "required|alpha_num_dash"
                  )}
                </div>
                <div className="col-md-3">
                  <button className="btn btn-secondary btn-block" type="submit">
                    <i className="fas fa-search mr-1"></i>Buscar
                  </button>
                </div>                
              </div>
            </form>
            <div className="row">
              <div className="col-md-9 mb-3 mt-3">
                {this.state.loading === 1 && (
                  <div className="text-center">
                    <h6>Cargando...</h6>
                    <div
                      className="spinner-border text-primary"
                      role="status"
                      aria-hidden="true"
                    ></div>
                  </div>
                )}
                {this.state.status === "success" && this.state.loading === 0 && (
                  <div className="card">
                    <table className="table-sm table-striped">
                      <thead className="table-dark">
                        <tr>
                          <th className="w-50">Sucursal</th>
                          <th className="w-25">Inventario</th>                          
                        </tr>
                      </thead>

                      <tbody>
                        {this.state.sucursales.map(item => {
                          return (
                            <tr key={item.id}>
                              <td>{item.almacen}</td>
                              <td>
                                {item.inventario >= 10 ? (
                                  <span className="badge badge-success mt-1">
                                    Disponible
                                  </span>
                                ) : item.inventario > 0 &&
                                  item.inventario < 10 ? (
                                  <span className="badge badge-warning mt-1">
                                    Disponible
                                  </span>
                                ) : (
                                  <span className="badge badge-danger mt-1">
                                    No Disponible
                                  </span>
                                )}
                              </td>                              
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>

        {/* FOOTER */}
        <Footer />
      </div>
    );
  }
}

export default Stock;
