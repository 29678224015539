import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import Swal from "sweetalert";

import Header from "./Header";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { getPedidos, numberFormat } from "../repository";

class Orders extends Component {
  constructor() {
    super();
    this.state = {
      pedidos: {},
      status: null
    };
  }

  obtienePedidos = () => {
    var user = JSON.parse(sessionStorage.getItem("usuario"));
    var idClienteGlobal = user[0].idClienteGlobal;

    getPedidos(idClienteGlobal, 50)
      .then(response => {
        if (response.status === "success") {
          this.setState({
            status: "success",
            pedidos: response.result
          });
          //console.log(response.result.length)
        } else {
          Swal(
            "Error obtener la información [Pedidos]",
            response.message,
            "error"
          );
        }
      })
      .catch(err =>
        Swal("Error obtener la información [Pedidos]", err, "error")
      );
  };

  componentDidMount() {
    this.obtienePedidos();
  }

  render() {
    //console.log(JSON.parse(localStorage.getItem("sucursal")))

    return (
      <div>
        {/* NAVBAR */}
        <NavBar />

        {/* HEADER */}
        <Header
          title="Pedidos"
          class1="row py-1 bg-warning text-black"
          class2="col-md-6"
          class3="fa fa-money-check-alt"
        />

        <section id="posts">
          <div className="container">
            <div className="row mt-3">
              {this.state.status !== "success" && (
                <div className="col-md-12 text-center">
                  <h6>Cargando...</h6>
                  <div
                    className="spinner-border text-warning"
                    role="status"
                    aria-hidden="true"
                  ></div>
                </div>
              )}

              {this.state.status === "success" &&
                this.state.pedidos.length === 0 && (
                  <Fragment>
                    <div className="col-md-3"></div>
                    <div className="col-md-6 text-center">
                      <small>
                        <strong>No se encontró información.</strong>
                      </small>
                      <div className="col-md-3"></div>
                    </div>
                  </Fragment>
                )}

              {this.state.status === "success" &&
                this.state.pedidos.length > 0 && (
                  <div className="col-md-9 mb-3">
                    <div className="card">
                      <table className="table-sm table-striped w-100">
                        <thead className="table-dark">
                          <tr>
                            <th className="text-center">#</th>
                            <th className="text-center">Total</th>
                            <th className="text-center">Fecha</th>
                            <th>&nbsp;</th>
                          </tr>
                        </thead>

                        <tbody>
                          {this.state.pedidos.map(item => {
                            return (
                              <tr key={item.idPedido}>
                                <td className="text-center">{item.idPedido}</td>
                                <td className="text-right">
                                  <strong>${numberFormat(item.Total)}</strong>
                                </td>
                                <td className="text-center">
                                  <Moment format="DD/MM/YY">
                                    {item.Fecha}
                                  </Moment>
                                </td>
                                <td className="text-center">
                                  <Link
                                    to={"/orderDetails/" + item.idPedido + "/2"}
                                    className="btn btn-secondary"
                                  >
                                    <i className="fas fa-angle-double-right"></i>{" "}
                                    Detalle
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </section>

        {/* FOOTER */}
        <Footer />
      </div>
    );
  }
}

export default Orders;
