import React, { Component } from "react";
import NumericInput from "react-numeric-input";

import global from "./Global";
import { numberFormat, addDefaultSrc } from "../repository";

class ProductItem extends Component {
  constructor() {
    super();

    this.state = {
      spinnerValue: 1,
    };
  }

  render() {
    const { item } = this.props;
    return (
      <div className="col-md-4 card-group">
        <div className="card mb-4 border-secondary">
          <div className="card-header text-center">{item.Familia}</div>

          <img
            style={{
              maxWidth: 100 + "%",
              height: 130 + "px",
              objectFit: "contain",
            }}
            src={
              global.fotos_URL +
              item.Codigo.toLowerCase().replace("-", "_") +
              ".jpg"
            }
            alt={item.Codigo}
            onError={addDefaultSrc}
          ></img>

          <div className="card-body">
            <h5 className="card-title">
              <span className="badge badge-secondary">{item.Codigo}</span>
              <div className="float-right">
                <span className="badge badge-pill badge-primary">
                  ${numberFormat(item.Precio)}
                </span>
              </div>
            </h5>
          </div>

          {item.Descripcion ? (
            <div className="card-body card-text h-25">
              {item.Descripcion}
            </div>
          ) : (
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <strong>Modelo : </strong>
                {item.Modelo}
              </li>
              <li className="list-group-item">
                <strong>Posición : </strong>
                {item.Posicion}
              </li>
              <li className="list-group-item">
                <strong>Condición : </strong>
                {item.Condicion}
              </li>
            </ul>
          )}

          <div className="card-footer text-center">
            {item && (
              <table className="table-sm w-100">
                <tbody>
                  <tr>
                    <td className="text-left">
                      <NumericInput
                        name="spinner"
                        min={1}
                        max={99}
                        value={this.state.spinnerValue}
                        size={7}
                        mobile={true}
                        className="btn-lg"
                        onChange={(value) => {
                          this.setState({
                            spinnerValue: value,
                          });
                        }}
                        maxLength={2}
                        style={{
                          "btnUp.mobile": {
                            width: "5.0ex",
                            bottom: 2,
                            boxShadow: "none",
                            borderRadius: 2,
                            borderWidth: 1,
                          },

                          "btnDown.mobile": {
                            width: "5.0ex",
                            bottom: 2,
                            left: 2,
                            top: 2,
                            right: "auto",
                            boxShadow: "none",
                            borderRadius: 2,
                            borderWidth: 1,
                          },
                        }}
                      />
                    </td>
                    <td className="text-right">
                      <button
                        className="btn btn-warning btn-block"
                        type="button"
                        onClick={() =>
                          this.props.add(
                            item,
                            this.state.spinnerValue === null
                              ? 1
                              : this.state.spinnerValue
                          )
                        }
                      >
                        <i className="fas fa-shopping-cart"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ProductItem;
