import React, { Component } from "react";
import NumericInput from "react-numeric-input";

import global from "./Global";
import { numberFormat, addDefaultSrc } from "../repository";

class CartItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      spinnerValue: this.props.item.Cantidad,
      inventario: this.props.item.Inventario,
    };
  }

  render() {
    const { item } = this.props;
    return (
      <li className="list-group-item">
        <div className="row">
          <div className="col-md-3 text-center">
            <img
              style={{
                maxWidth: 80 + "%",
                height: 100 + "px",
                objectFit: "contain",
              }}
              src={
                global.fotos_URL +
                item.Codigo.toLowerCase().replace("-", "_") +
                ".jpg"
              }
              alt={item.Codigo}
              onError={addDefaultSrc}
            ></img>
          </div>
          <div className="col-md-9 text-center">
            <h5>
              <span className="badge badge-secondary float-left mt-1">
                {item.Codigo}
              </span>
              {this.state.inventario >= this.state.spinnerValue ? (
                <span className="badge badge-success mt-1">Disponible</span>
              ) : (
                <span className="badge badge-danger mt-1">No Disponible</span>
              )}
              <span className="badge badge-pill badge-primary float-right mt-1">
                ${numberFormat(item.Precio)}
              </span>
            </h5>
            <div className="text-center">
              {item.Descripcion
                ? item.Descripcion
                : item.Familia +
                  " / " +
                  item.Marca +
                  " / " +
                  item.Vehiculo +
                  " / " +
                  item.Modelo +
                  " / " +
                  item.Posicion +
                  " / " +
                  item.Condicion}
            </div>
            <div className="text-center mt-3">
              <NumericInput
                name="spinner"
                key={item.idProducto}
                min={1}
                max={99}
                value={this.state.spinnerValue}
                size={7}
                mobile={true}
                className="btn-lg"
                onChange={(value) => (
                  this.setState({
                    spinnerValue: isNaN(Number.parseInt(value))
                      ? 1
                      : Number.parseInt(value),
                  // eslint-disable-next-line no-sequences
                  }),
                  this.props.update(
                    item,
                    isNaN(Number.parseInt(value)) ? 1 : Number.parseInt(value)
                  )
                )}
                maxLength={2}
                style={{
                  "btnUp.mobile": {
                    width: "5.0ex",
                    bottom: 2,
                    boxShadow: "none",
                    borderRadius: 2,
                    borderWidth: 1,
                  },

                  "btnDown.mobile": {
                    width: "5.0ex",
                    bottom: 2,
                    left: 2,
                    top: 2,
                    right: "auto",
                    boxShadow: "none",
                    borderRadius: 2,
                    borderWidth: 1,
                  },
                }}
              />

              <button
                className="btn btn-secondary ml-5"
                type="button"
                onClick={() => this.props.remove(item)}
              >
                <i className="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </li>
    );
  }
}

export default CartItem;
