import axios from "axios";
import global from "./components/Global";

export function login(data) {
  var usuario = data.usuario;
  var contrasenia = data.contrasenia;

  var apiCall =
    global.apiRESTUrl + "/obtieneUsuario/" + usuario + "/" + contrasenia;
  //console.log(apiCall);

  return (
    axios
      .get(apiCall)
      .then((response) => {
        //console.log(response);

        if (response.data.status === "success") {
          //Usuario
          //console.log(response.data.result);
          sessionStorage.setItem(
            "usuario",
            JSON.stringify(response.data.result)
          );

          //Token
          sessionStorage.setItem("x-access-token", response.data.token);

          //Token expira en 12 horas
          sessionStorage.setItem(
            "x-access-token-expiration",
            Date.now() + 12 * 60 * 60 * 1000
          );
        }

        return response.data;
      })
      //.catch(err => Promise.reject(err));
      .catch((error) => {
        console.log(error);
        return error.response.data;
      })
  );
}

export function isAuthenticated() {
  //return false;

  return (
    sessionStorage.getItem("x-access-token") &&
    sessionStorage.getItem("x-access-token-expiration") > Date.now()
  );
}

export function getSucursales(latitude, longitude) {
  //console.log(latitude + "," + longitude)

  var apiCall =
    global.apiRESTUrl + "/obtieneSucursales/" + latitude + "/" + longitude;
  //console.log(apiCall);

  return (
    axios
      .get(apiCall)
      .then((response) => {
        //console.log(response.data);
        return response.data;
      })
      //.catch(err => Promise.reject(err));
      .catch((error) => {
        console.log(error);
        return error.response.data;
      })
  );
}

export function getMarcas() {
  var apiCall = global.apiRESTUrl + "/obtieneMarcas";

  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error.response.data;
    });
}

export function getVehiculos(idMarca) {
  var apiCall = global.apiRESTUrl + "/obtieneVehiculos/" + idMarca;

  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error.response.data;
    });
}

export function getModelos(idTipoVehiculo) {
  var apiCall = global.apiRESTUrl + "/obtieneModelos/" + idTipoVehiculo;

  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error.response.data;
    });
}

export function getFamilias(idTipoVehiculo, modelo) {
  var apiCall =
    global.apiRESTUrl + "/obtieneFamilias/" + idTipoVehiculo + "/" + modelo;

  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error.response.data;
    });
}

export function getProductos(idTipoVehiculo, idFamilia, modelo) {
  var apiCall =
    global.apiRESTUrl +
    "/obtieneProductos/" +
    idTipoVehiculo +
    "/" +
    idFamilia +
    "/" +
    modelo;

  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error.response.data;
    });
}

export function numberFormat(value) {
  return new Intl.NumberFormat("en-US", {
    style: "decimal", //currency
    currency: "MXN",
    currencyDisplay: "symbol",
    minimumFractionDigits: 0,
  }).format(value);
}

export function addDefaultSrc(ev) {
  ev.target.src = global.foto_nd;
}

export async function getInventario(idAlmacen, codigo, idMarca) {
  var apiCall =
    global.apiRESTUrl +
    "/obtieneInventario/" +
    idAlmacen +
    "/" +
    codigo +
    "/" +
    idMarca;

  const response = await axios.get(apiCall).catch((error) => {
    return error;
  });

  //console.log(response);
  //console.log(response.data);

  try {
    if (response.data.status === "success") {
      //console.log(response.data.result[0].inventario);
      return response.data.result[0].inventario;
    } else return 0;
  } catch (error) {
    return 0;
  }
}

export async function agregaPedidos(
  idClienteGlobal,
  idAlmacenDestino,
  total,
  consignado
) {
  var apiCall = global.apiRESTUrl + "/agregaPedidos";

  const response = await axios
    .post(apiCall, {
      idClienteGlobal: idClienteGlobal,
      idAlmacenDestino: idAlmacenDestino,
      total: total,
      consignado: consignado,
    })
    .catch((error) => {
      return error;
    });

  try {
    if (response.data.status === "success") {
      //console.log(response.data.result[0].inventario);
      return response.data.result[0].idPedido;
    } else return 0;
  } catch (error) {
    return 0;
  }
}

export async function agregaDetallePedidos(
  idPedido,
  producto,
  descripcion,
  cantidad,
  precio,
  idMarca
) {
  var apiCall = global.apiRESTUrl + "/agregaDetallePedidos";

  const response = await axios
    .post(apiCall, {
      idPedido: idPedido,
      producto: producto,
      descripcion: descripcion,
      cantidad: cantidad,
      precio: precio,
      idMarca: idMarca,
    })
    .catch((error) => {
      return error;
    });

  try {
    if (response.data.status === "success") {
      //console.log(response.data.result[0].inventario);
      return response.data.result[0].Message;
    } else return 0;
  } catch (error) {
    return 0;
  }
}

export function getPedidos(idClienteGlobal, top) {
  var apiCall =
    global.apiRESTUrl + "/obtienePedidos/" + idClienteGlobal + "/" + top;

  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error.response.data;
    });
}

export function getNumPedidos(idClienteGlobal) {
  var apiCall = global.apiRESTUrl + "/obtieneNumPedidos/" + idClienteGlobal;

  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error.response.data;
    });
}

export function getPedido(idPedido) {
  var apiCall = global.apiRESTUrl + "/obtienePedido/" + idPedido;

  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error.response.data;
    });
}

export function getInventarioZona(idAlmacen, codigo, idMarca) {
  var apiCall =
    global.apiRESTUrl +
    "/obtieneInventarioZona/" +
    idAlmacen +
    "/" +
    codigo +
    "/" +
    idMarca;

  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error.response.data;
    });
}

export function getAllVehiculos() {
  var apiCall = global.apiRESTUrl + "/obtieneTodosVehiculos";

  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error.response.data;
    });
}

export function getProductosByCode(codigo, equivalencias) {
  var apiCall =
    global.apiRESTUrl +
    "/obtieneProductosByCode/" +
    codigo +
    "/" +
    equivalencias;

  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error.response.data;
    });
}

export function getProductosByDescripcion(desc) {
  var apiCall = global.apiRESTUrl + "/obtieneProductosByDescripcion/" + desc 

  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error.response.data;
    });
}

export function getProductosByDescCode(codigo) {
  var apiCall =
    global.apiRESTUrl +
    "/obtieneProductosByDescCode/" +
    codigo;

  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error.response.data;
    });
}
