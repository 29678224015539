import React, { Component, Fragment } from "react";
import { geolocated } from "react-geolocated";

import Header from "./Header";
import Footer from "./Footer";
import LocationItems from "./LocationItems";

class Location extends Component {
  constructor() {
    super();
    this.state = {
      displayBasic: false,
      position: "center",
    };

    this.onClick = this.onClick.bind(this);
    this.onHide = this.onHide.bind(this);
  }

  onClick(name, position) {
    let state = {
      [`${name}`]: true,
    };

    if (position) {
      state = {
        ...state,
        position,
      };
    }

    this.setState(state);
  }

  onHide(name) {
    this.setState({
      [`${name}`]: false,
    });
  }

  render() {
    return (
      <div>
        {/* NAVBAR */}
        <nav className="navbar navbar-expand-sm navbar-dark bg-dark p-0">
          <div className="container">
            <div className="navbar-brand">&nbsp;GoviNet</div>
          </div>
        </nav>

        {/* HEADER */}
        <Header
          title="Pedidos Sucursales"
          class1="row py-1 bg-warning text-black"
          class2="col-md-6"
          class3="fa fa-store-alt"
        />

        {/* CONTENT */}
        <section id="content">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mb-3 mt-3">
                {!this.props.isGeolocationAvailable ? (
                  <h6 className="text-center">
                    Su Navegador Web no soporta Geolocalización.
                  </h6>
                ) : !this.props.isGeolocationEnabled ? (
                  <div className="text-center">
                    <h6>Cargando...</h6>
                    <div
                      className="spinner-border text-warning"
                      role="status"
                      aria-hidden="true"
                    ></div>
                  </div>
                ) : this.props.coords ? (
                  <Fragment>
                    {/*Mapa*/}

                    <LocationItems
                      latitude={this.props.coords.latitude}
                      longitude={this.props.coords.longitude}
                      map={true}
                    />

                    {/*Sucursales*/}
                    <h6 className="mt-3">
                      <strong>Seleccione una Sucursal:</strong>
                    </h6>
                    <LocationItems
                      latitude={this.props.coords.latitude}
                      longitude={this.props.coords.longitude}
                      map={false}
                    />
                  </Fragment>
                ) : (
                  <div className="text-center">
                    <h6>Cargando...</h6>
                    <div
                      className="spinner-border text-warning"
                      role="status"
                      aria-hidden="true"
                    ></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>

        {/* FOOTER */}
        <Footer />
      </div>
    );
  }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: true,
    maximumAge: 0,
    timeout: Infinity,
  },
  watchPosition: false,
  userDecisionTimeout: null,
  suppressLocationOnMount: false,
  geolocationProvider: navigator.geolocation,
  isOptimisticGeolocationEnabled: false,
})(Location);
