import React, { Component } from "react";
import Swal from "sweetalert";

import Header from "./Header";
import NavBar from "./NavBar";
import Footer from "./Footer";
import SearchByMaker from "./SearchByMaker";
import SearchByVehicle from "./SearchByVehicle";
import SearchByCode from "./SearchByCode";
import SearchByDesc from "./SearchByDesc";
import { getInventario } from "../repository";

class Search extends Component {
  constructor() {
    super();

    this.addToCart = this.addToCart.bind(this);

    var sucursal = JSON.parse(localStorage.getItem("sucursal"));

    this.state = {
      idSucursal: sucursal.idSucursal,
    };
  }

  addToCart = async (item, cant) => {
    try {
      let cart = sessionStorage.getItem("cart")
        ? JSON.parse(sessionStorage.getItem("cart"))
        : {};

      let idProducto = item.idProducto;
      cart[idProducto] = cart[idProducto] ? cart[idProducto] : item;
      cart[idProducto].Cantidad += cant;

      //Calculo el Inventario
      let inventario = await getInventario(
        this.state.idSucursal,
        item.Codigo,
        item.idMarcaProducto
      );
      cart[idProducto].Inventario = inventario;

      //console.log(cart)

      sessionStorage.setItem("cart", JSON.stringify(cart));

      this.refs.navBarRef.getCartItems();

      Swal(
        "Producto agregado",
        "Cuenta con [" +
          cart[idProducto].Cantidad +
          "] productos [" +
          cart[idProducto].Codigo +
          "] en su Carrito de Compras.",
        "success"
      );
    } catch (error) {
      Swal(
        "Error en Carrito de Compras",
        "No se pudo agregar su Producto al Carrito de Compras.",
        "error"
      );
    }
  };

  render() {
    return (
      <div>
        {/* NAVBAR */}
        <NavBar ref="navBarRef" />

        {/* HEADER */}
        <Header
          title="Búsqueda de Productos"
          class1="row py-1 bg-warning text-black"
          class2="col-md-6"
          class3="fa fa-search"
        />

        {/* CONTENT */}
        <section id="search">
          <div className="container">
            <div className="row mt-3">
              <div className="col-md-12">
                <nav>
                  <div
                    className="nav nav-tabs bg-light"
                    id="nav-tab"
                    role="tablist"
                  >
                    <a
                      className="nav-item nav-link active"
                      id="nav-1-tab"
                      data-toggle="tab"
                      href="#nav-1"
                      role="tab"
                      aria-controls="nav-1"
                      aria-selected="true"
                    >
                      Vehículo
                    </a>
                    <a
                      className="nav-item nav-link"
                      id="nav-2-tab"
                      data-toggle="tab"
                      href="#nav-2"
                      role="tab"
                      aria-controls="nav-2"
                      aria-selected="false"
                    >
                      Marca
                    </a>
                    <a
                      className="nav-item nav-link"
                      id="nav-3-tab"
                      data-toggle="tab"
                      href="#nav-3"
                      role="tab"
                      aria-controls="nav-3"
                      aria-selected="false"
                    >
                      Código
                    </a>
                    <a
                      className="nav-item nav-link"
                      id="nav-4-tab"
                      data-toggle="tab"
                      href="#nav-4"
                      role="tab"
                      aria-controls="nav-4"
                      aria-selected="false"
                    >
                      Descripción
                    </a>
                  </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-1"
                    role="tabpanel"
                    aria-labelledby="nav-1-tab"
                  >
                    <SearchByVehicle addToCart={this.addToCart} />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-2"
                    role="tabpanel"
                    aria-labelledby="nav-2-tab"
                  >
                    <SearchByMaker addToCart={this.addToCart} />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-3"
                    role="tabpanel"
                    aria-labelledby="nav-3-tab"
                  >
                    <SearchByCode addToCart={this.addToCart} />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-4"
                    role="tabpanel"
                    aria-labelledby="nav-4-tab"
                  >
                    <SearchByDesc addToCart={this.addToCart} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* FOOTER */}
        <Footer />
      </div>
    );
  }
}

export default Search;
