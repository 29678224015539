var Global = {
    /*************** Desarrollo *****************/
    //API REST de AZURE
    //apiRESTUrl : "http://localhost:3001/api",

    //Google Cloud Platform
    //google_API_KEY: "",
        
    /*************** Produccion *****************/
    //API REST de AZURE
    apiRESTUrl : "https://webapigovinetnodejs.azurewebsites.net/api",

    //Google Cloud Platform --PROD
    google_API_KEY: "AIzaSyDXGX-3lntnQkDfn32Af5f1JJz1S0sUamI", 
    

    /*************** Generales *****************/    
    //Google Maps Platform
    google_maps_URL: "https://www.google.com/maps/dir/?api=1&destination=",

    fotos_URL: "https://storagegovi.blob.core.windows.net/fotos/i_",

    foto_nd: "https://storagegovi.blob.core.windows.net/fotos/img_nd.png"
};

export default Global;