import React, { Component } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert";

import { getNumPedidos } from "../repository";

class SideBar extends Component {
  constructor() {
    super();
    this.state = {
      cartItems: 0,
      orderItems: 0
    };
  }

  getCartItems = () => {
    if (sessionStorage.getItem("cart") !== null) {
      let cart = JSON.parse(sessionStorage.getItem("cart"));

      this.setState({
        cartItems: Object.keys(cart).length
      });
    }
  };

  getNumOrders = () => {
    var user = JSON.parse(sessionStorage.getItem("usuario"));
    var idClienteGlobal = user[0].idClienteGlobal;

    getNumPedidos(idClienteGlobal)
      .then(response => {
        if (response.status === "success") {
          this.setState({
            status: "success",
            orderItems: response.result[0].NumPedidos
          });
        } else {
          Swal(
            "Error obtener la información [Pedidos]",
            response.message,
            "error"
          );
        }
      })
      .catch(err =>
        Swal("Error obtener la información [Pedidos]", err, "error")
      );

  };

  componentDidMount() {
    this.getCartItems();
    this.getNumOrders();
  }

  render() {
    return (
      <div className="col-md-3">
        <div className="card text-center bg-success text-white mb-3">
          <div className="card-body">
            <h3>Carrito</h3>
            <h4 className="display-4">
              <i className="fas fa-shopping-cart"></i>&nbsp;
              {this.state.cartItems}
            </h4>
            <Link to="/cart" className="btn btn-outline-light btn-sm">
              Ver más
            </Link>
          </div>
        </div>

        <div className="card text-center bg-primary text-white mb-3">
          <div className="card-body">
            <h3>Inventarios</h3>
            <h4 className="display-4">
              <i className="fas fa-cubes"></i>&nbsp;
            </h4>
            <Link to="/stock" className="btn btn-outline-light btn-sm">
              Ver más
            </Link>
          </div>
        </div>

        <div className="card text-center bg-warning text-white mb-3">
          <div className="card-body">
            <h3>Pedidos</h3>
            <h4 className="display-4">
              <i className="fas fa-folder-open"></i>&nbsp;
              {this.state.orderItems}
            </h4>
            <Link to="/orders" className="btn btn-outline-light btn-sm">
              Ver más
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default SideBar;
