import React, { Component, Fragment } from "react";
import SimpleReactValidator from "simple-react-validator";
import Swal from "sweetalert";

import { getProductosByCode } from "../repository";
import ProductItem from "./ProductItem";

class SearchByCode extends Component {
  codigoRef = React.createRef();

  constructor() {
    super();
    this.toggleEquivalencias = this.toggleEquivalencias.bind(this);
    this.changeState = this.changeState.bind(this);

    this.state = {
      equivalencias: false,
      codigo: "",
      loading: 0,
      productos: [],
      status: null,
    };
  }

  componentWillMount() {
    this.validator = new SimpleReactValidator({
      element: (message) => (
        <span className="text-danger">
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {` ${message}`}
        </span>
      ),
      messages: {
        default: "Campo Requerido",
      },
    });
  }

  toggleEquivalencias = () => {
    this.setState({ equivalencias: !this.state.equivalencias });

    //console.log(!this.state.equivalencias);
  };

  changeState = () => {
    this.setState({
      codigo: this.codigoRef.current.value,
      productos: [],
      status: null
    });
  };

  //Search Products
  search = (e) => {
    e.preventDefault();

    this.setState({ 
      loading: 1,
      productos: [],
      status: null
    });

    if (this.validator.allValid()) {
      //console.log("Validator Ok", this.state.codigo, this.state.equivalencias);
      
      getProductosByCode(
        this.state.codigo,
        this.state.equivalencias === true ? 1 : 0
      )
        .then((response) => {
          //console.log(response.result);

          if (response.status === "success") {
            this.setState({
              status: "success",
              productos: response.result,
              loading: 0,
            });
          } else {
            this.setState({ loading: 0 });
            Swal(
              "Error obtener la información [Productos]",
              response.message,
              "error"
            );
          }
        })
        .catch((err) => {
          this.setState({ loading: 0 });
          Swal("Error obtener la información [Productos]", err, "error");
        });        
    } else {
      //console.log("Validator-Failed");
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({ loading: 0 });
    }
  };

  render() {
    if (this.state.status === "success") {
      var productos = this.state.productos;

      //console.log("productos:" + productos.length);
    }

    return (
      <div>
        {/* CONTENT */}
        <section id="searchContent" className="mt-3">
          <form onSubmit={this.search}>
            <div className="container">
              <div className="row">
                <div className="col-md-1 mt-2">
                  <h6>Código:</h6>
                </div>
                <div className="col-md-3">
                  <input
                    name="txtCodigo"
                    type="text"
                    className="form-control"
                    defaultValue=""
                    ref={this.codigoRef}
                    autoComplete="off"
                    placeholder="Capture el Código"
                    onChange={this.changeState}
                    maxLength="20"
                  />
                  {this.validator.message(
                    "txtCodigo",
                    this.state.codigo,
                    "required|alpha_num_dash"
                  )}
                </div>
                <div className="col-md-3 custom-control custom-checkbox mt-2 ml-3 mb-3">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    name="chkEquivalencias"
                    id="chkEquivalencias"
                    onChange={this.toggleEquivalencias}
                    checked={this.state.equivalencias}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="chkEquivalencias"
                  >
                    Procesar Equivalencias
                  </label>
                </div>
                <div className="col-md-3">
                  <button className="btn btn-warning btn-block" type="submit">
                    <i className="fas fa-search mr-1"></i>Buscar
                  </button>
                </div>
              </div>
              {this.state.loading === 1 && (
                <div className="text-center mb-3 mt-3">
                  <h6>Cargando...</h6>
                  <div
                    className="spinner-border text-warning"
                    role="status"
                    aria-hidden="true"
                  ></div>
                </div>
              )}
              {this.state.status === "success" && (
                <Fragment>
                  <div className="row mt-3">
                    <div className="col-md-3"></div>
                    <div className="col-md-6 text-center">
                      <h6>
                        <span className="badge badge-warning ml-1 mr-1">
                          {productos.length}
                        </span>
                        <small>
                          <strong>Producto(s) encontrado(s)</strong>
                        </small>
                      </h6>
                      <div className="col-md-3"></div>
                    </div>
                  </div>
                  <div className="row">
                    {productos.map((item, index) => (
                      <ProductItem
                        item={item}
                        key={index}
                        add={this.props.addToCart}
                      />
                    ))}
                  </div>
                </Fragment>
              )}
            </div>
          </form>
        </section>
      </div>
    );
  }
}

export default SearchByCode;
