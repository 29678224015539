import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import Login from "./components/Login";
import Location from "./components/Location";
import Home from "./components/Home";
import Search from "./components/Search";
import Cart from "./components/Cart";
import OrderDetails from "./components/OrderDetails";
import Orders from "./components/Orders";
import Stock from "./components/Stock";

//import Test from "./components/test";

import NotFound from "./components/NotFound";
import { isAuthenticated } from "./repository";

class Router extends Component {
  render() {
    return (
      <BrowserRouter>
        {/* Configuración de Rutas y Páginas */}
        <Switch>
          <Route exact path="/" component={Login} />

          <PrivateRoute exact path="/location">
            <Location />
          </PrivateRoute>

          <PrivateRoute exact path="/home">
            <Home />
          </PrivateRoute>

          <PrivateRoute exact path="/search">
            <Search />
          </PrivateRoute>

          <PrivateRoute exact path="/cart">
            <Cart />
          </PrivateRoute>

          <Route
            exact
            path="/orderDetails/:idPedido/:source"
            render={props => <OrderDetails {...props} />}
          />

          <PrivateRoute exact path="/orders">
            <Orders />
          </PrivateRoute>

          <PrivateRoute exact path="/stock">
            <Stock />
          </PrivateRoute>

          <Route component={NotFound} />

          {/*
          <PrivateRoute exact path="/test">
            <Test />
          </PrivateRoute>
          */}
        </Switch>
      </BrowserRouter>
    );
  }
}

const auth = isAuthenticated();

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...props }) {
  //console.log("auth: " + auth);

  return (
    <Route
      {...props}
      render={({ location }) =>
        auth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export default Router;
