import React, { Component } from "react";
import { Gmaps, Marker, Circle, InfoWindow } from "react-gmaps";

import global from "./Global";
import { getSucursales } from "../repository";
import LocationItem from "./LocationItem";
import Icon from "../images/marker-48-1.png";

class LocationItems extends Component {
  constructor() {
    super();
    this.state = {
      status: "",
      sucursales: []
    };
  }

  getItems() {
    getSucursales(this.props.latitude, this.props.longitude)
      .then(response => {
        if (response.status === "success") {
          this.setState({
            status: "success",
            sucursales: response.sucursales
          });
        } else {
          this.setState({
            status: "failed"
          });
        }
      })
      .catch(error => {
        //console.log(error);

        this.setState({
          status: "failed"
        });
      });
  }

  componentDidMount() {
    this.getItems();
  }

  onMapCreated(map) {
    map.setOptions({
      disableDefaultUI: false
    });
  }

  render() {
    const params = { key: global.google_API_KEY };

    if (this.state.status === "success") {
      var sucursales = this.state.sucursales;

      //console.log("sucursales:" + sucursales.length);
      //console.log("sucursal:" + JSON.stringify(sucursales[0]));

    }

    return (
      this.state.status === "success" &&
      (this.props.map ? (
        <Gmaps
          width={"100%"}
          height={"300px"}
          lat={this.props.latitude}
          lng={this.props.longitude}
          zoom={11}
          params={params}
          onMapCreated={this.onMapCreated}
        >
          {sucursales.map((item, index) => (
            <Marker
              key={index}
              lat={item.Coordenadas.split(",")[0]}
              lng={item.Coordenadas.split(",")[1]}
              label={item.Sucursal}
              title={item.Distancia + " (" + item.Duracion + ")"}
              icon={Icon}
            />
          ))}

          <Circle
            lat={this.props.latitude}
            lng={this.props.longitude}
            radius={1000}
            strokeColor={"#FF0000"}
            strokeWeight={1}
            fillColor={"#FF0000"}
          />
          <InfoWindow
            lat={this.props.latitude}
            lng={this.props.longitude}
            content={"Tú estás aquí"}
          />
        </Gmaps>
      ) : (
        <div className="row">
          {sucursales.map((item, index) => (
            <LocationItem item={item} key={index} />
          ))}
        </div>
      ))
    );
  }
}

export default LocationItems;
