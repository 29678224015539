import React, { Component, Fragment } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Link } from "react-router-dom";
import Swal from "sweetalert";

import Header from "./Header";
import NavBar from "./NavBar";
import Footer from "./Footer";
import OrderItem from "./OrderItem";
import { numberFormat, getPedido } from "../repository";

class OrderDetails extends Component {
  constructor() {
    super();

    this.getOrder = this.getOrder.bind(this);

    this.state = {
      status: null,
      pedido: {},
      detallePedido: {},
      source: "",
      items: 0
    };
  }

  getOrder = () => {
    try {
      //params url
      var idPedido = this.props.match.params.idPedido;
      var source = this.props.match.params.source;

      this.setState({ source: source });

      getPedido(idPedido)
        .then(response => {
          //console.log(response);
          if (response.status === "success") {

            var pedido = response.result.recordsets[0];
            var detallePedido = response.result.recordsets[1];
            var items = 0;

            //Calculo los items
            detallePedido.forEach((item) => {
              items += item.Cantidad;
            });
  
            this.setState({
              status: "success",
              pedido: pedido,
              detallePedido: detallePedido,
              items: items
            });
          } else {
            Swal(
              "Error obtener la información [Productos]",
              response.message,
              "error"
            );
          }
        })
        .catch(err =>
          Swal("Error obtener la información [Pedido]", err, "error")
        );
    } catch (err) {
      Swal("Error obtener la información [Pedido]", err, "error");
    }
  };

  componentDidMount() {
    this.validator = new SimpleReactValidator({
      element: message => (
        <span className="text-danger">
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {` ${message}`}
        </span>
      ),
      messages: {
        default: "Campo Requerido"
      }
    });

    this.getOrder();
  }

  render() {
    if (this.state.status === "success") {
      var pedido = this.state.pedido;
      var detallePedido = this.state.detallePedido;
    }
    return (
      <div>
        {/* NAVBAR */}
        <NavBar />

        {/* HEADER */}
        <Header
          title={
            this.state.status === "success"
              ? "Pedido #" + pedido[0].idPedido
              : "Pedido"
          }
          class1="row py-1 bg-warning text-black"
          class2="col-md-6"
          class3="fa fa-money-check-alt"
        />

        <section id="actions" className="py-3 mb-2">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <Link
                  to={this.state.source === "1" ? "/home" : "/orders"}
                  className="btn btn-secondary btn-block mb-2"
                >
                  <i className="fas fa-arrow-left"></i> Regresar
                </Link>
              </div>
              {this.state.status === "success" && (
                <div className="col-md-9">
                  <h6>
                    <strong>Sucursal: </strong>
                    {this.state.pedido[0].AlmacenDestino}
                  </h6>
                  <h6>
                    <strong>Fecha: </strong>
                    {this.state.pedido[0].Fecha}
                  </h6>
                </div>
              )}
            </div>
          </div>
        </section>

        {/* CONTENT */}
        <section id="items">
          <form onSubmit={this.search}>
            <div className="container">
              {this.state.status !== "success" && (
                <div className="row">
                  <div className="col-md-3"></div>
                  <div className="col-md-6 text-center">
                    <div className="text-center">
                      <h6>Cargando...</h6>
                      <div
                        className="spinner-border text-warning"
                        role="status"
                        aria-hidden="true"
                      ></div>
                    </div>
                    <div className="col-md-3"></div>
                  </div>
                </div>
              )}

              {this.state.status === "success" && (
                <Fragment>
                  <div className="row">
                    <div className="col-md-12">
                      <ul className="list-group">
                        {detallePedido.map((item, index) => (
                          <OrderItem item={item} key={index} />
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-12 text-right">
                      <h5>
                        <span className="badge badge-warning ml-1 mr-1">
                          {this.state.items}
                        </span>
                        <small>
                          <strong>Productos:</strong>
                        </small>
                        <span className="badge badge-pill badge-warning ml-2 mr-3">
                          ${numberFormat(this.state.pedido[0].Total)} + IVA
                        </span>
                      </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mt-3">
                      Comentarios:
                      <textarea
                        name="txtConsignado"
                        className="form-control"
                        defaultValue={this.state.pedido[0].Consignado}
                        readOnly
                      />
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          </form>
        </section>

        {/* FOOTER */}
        <Footer />
      </div>
    );
  }
}

export default OrderDetails;
